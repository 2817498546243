#splash {
  background-image: url("/assets/images/smokeAndFumes.jpg");
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  #splash {
    width: 100%;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }
}
.hero.welcome-hero {
  height: auto;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 768px) {
  .welcome-hero {
    background-color: white;
    margin-top: 80px;
  }
}
.knew.welcome-knew {
  padding-top: 75px;
}
.hero-text.welcome-hero-text {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .hero-text.welcome-hero-text {
    text-align: center;
    color: black;
  }
}
.smoke,
.fumes {
  margin: 0 10%;
  flex: 1 1 50%;
}
.enter-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.button.enter-button {
  width: 200px;
  padding: 20px;
  background-color: #545C75;
  color: white;
  border-radius: 2%;
}
@media screen and (max-width: 768px) {
  .enter-buttons {
    margin-bottom: 80px;
  }
  .smoke,
  .fumes {
    margin: 0 10px;
  }
  .button.enter-button {
    width: 150px;
    margin-top: 50px;
  }
}
